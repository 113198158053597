.container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    background-color: lightgray;
    width: 100%;
}

.header {
    flex-direction: row;
    align-content: flex-start;
    background-color: lightgray;
    font-weight: 700;
    text-decoration: underline;
    text-align: center;
    padding: 20px;
}
.vertical-line {
    width: 2px; /* Breite des Strichs */
    background-color: #757474; /* Farbe des Strichs */
    height: auto; /* Volle Höhe des Containers */
    margin: 20px 20px; /* Abstand zwischen den Spalten */
    align-self: stretch;
    z-index: 99;
  }

.column {
    margin: 10px;
    display: flex;
    flex-direction: column;
}

.images {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    background-color: lightgray;
    width: 60%;
}

.left {
    flex-basis: 66%;
}

.right {
    flex-basis: 33%;
}

.aspect-16-9,
.aspect-1-1 {
    position: relative;
    width: 100%;
}

/* Überschrift zentriert */

.aspect-16-9 {
    padding-top: 56.25%;
    /* 16:9 Verhältnis */
}

.aspect-1-1 {
    padding-top: 100%;
    /* 1:1 Verhältnis */
}

.aspect-16-9 img,
.aspect-1-1 img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* Skaliert das Bild ohne Verzerrung */
}