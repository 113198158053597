@import url('https://fonts.googleapis.com/css?family=Josefin+Sans');

.container {
    display: flex;
    font-family: Poppins;
    flex-direction: row;
    background-color: #F6F8FC !important;
}

.mainContainer {
    display: flex;
    font-family: Poppins;
    flex-direction: column;
    background-color: #F6F8FC !important;
}

.targetTypeContainer {
    margin-top: 40px;
}

.actionButton {
    width: 10%;
    align-self: flex-start;
    margin-left: 0;
    color: white;
    background: linear-gradient(90deg, rgba(74, 84, 255, 0.9) 0%, rgba(0, 102, 255, 0.6) 100%) !important;
    border: 1px solid rgba(252, 252, 252, 0.5) !important;
    box-sizing: border-box !important;
    /* Primary */
    box-shadow: 0px 16px 24px rgba(160, 163, 189, 0.16) !important;
    backdrop-filter: blur(12px) !important;

    /* Note: backdrop-filter has minimal browser support */
    border-radius: 54px !important;
}

.buttonContainer {
    display: flex;
    flex-grow: 0;
    justify-content: space-between;
    gap: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
}
.defaultButton {
    flex:1;
    margin-left: 0;
    margin-top: 20px;
    color: white;
    background: linear-gradient(90deg, rgba(74, 84, 255, 0.9) 0%, rgba(0, 102, 255, 0.6) 100%) !important;
    border: 1px solid rgba(252, 252, 252, 0.5) !important;
    box-sizing: border-box !important;
    width: 100%;
    /* Primary */
    box-shadow: 0px 16px 24px rgba(160, 163, 189, 0.16) !important;
    backdrop-filter: blur(12px) !important;

    /* Note: backdrop-filter has minimal browser support */
    border-radius: 54px !important;
}

.deleteButton {
    flex:1;
    margin-left: 0;
    margin-top: 20px;
    color: white;
    background: linear-gradient(90deg, rgba(255, 74, 74, 0.9) 0%, rgba(255, 0, 0, 0.6) 100%) !important;
    border: 1px solid rgba(252, 252, 252, 0.5) !important;
    box-sizing: border-box !important;
    width: 100%;
    /* Primary */
    box-shadow: 0px 16px 24px rgba(160, 163, 189, 0.16) !important;
    backdrop-filter: blur(12px) !important;

    /* Note: backdrop-filter has minimal browser support */
    border-radius: 54px !important;
}

.statisticsContainer {
    flex-direction: row;
    margin-top: 40px;
}

.displayButton {
    flex-grow: 1;
    /*taken over from figma*/
    margin-top: -5px;
    background: #FFFFFF;
    border-radius: 100px !important;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    border-width: 0;
    color: #7F8FA6;
    margin-right: 10px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;

}

.displayButton:disabled {
    flex-grow: 1;
    /*taken over from figma*/
    margin-top: -5px;
    background: #FFFFFF;
    border-radius: 100px !important;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    border-width: 0;
    color: #7F8FA6;
    margin-right: 10px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;

}

.selectButton {
    width: 33%;
    flex-grow: 1;
    /*taken over from figma*/
    margin-top: 10px;
    background: #FFFFFF;
    border-radius: 100px !important;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    border-width: 0;
    color: #7F8FA6;
    margin-right: 10px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}
.expiresContainer {
    margin-bottom: 40px;
    margin-top: 40px;
}
.defaultInput {
    margin-bottom: 30px;
    width: 100%;
    height: 40px;
    z-index: 10;
    width: 100%;
    /*taken over from figma*/
    background: linear-gradient(92.46deg, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.7) 100%);
    border: 2px solid rgba(0, 0, 0, 0.3);
    box-sizing: border-box;
    box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(12px);
    border-radius: 12px;
    margin-bottom: 20px;
}
.advertisementLocation{
 margin-top: 60px;
}
.mainColumn {
    align-items: center;
    flex: 1;
    padding: 20px;
    
}
.headline{
    width: 50%;
    margin: 0 auto;
   
}
.twoRowContainer {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.rowContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.numberComponent {
    width: 20%;
    margin-right: 50px;
    margin-top: 20px;
}

.GeoInput {
    margin-right: 50px;
}
