.container_row {
    display: flex;
    flex-direction: row;
    justify-content: top;
}

.container_column {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-left: 30px;
    flex-grow: 0;
    width: 50%;
}
.full_column {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-left: 30px;
    flex-grow: 50%;
}

.pagination {
    display: flex;
    list-style: none;
    padding: 0;
    margin-bottom: 30px;
    margin-top: 30px;
    justify-content: center;

}

.pagination li {
    margin: 0 0px;
}

.action_button {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    justify-items: first baseline;
    height: 35px;
    margin-right: 30px;
    background: linear-gradient(90deg, rgba(74, 84, 255, 0.9) 0%, rgba(0, 102, 255, 0.6) 100%) !important;
    border: 1px solid rgba(252, 252, 252, 0.5) !important;
    box-sizing: border-box !important;
    cursor: pointer;
    /* Primary */
    box-shadow: 0px 16px 24px rgba(160, 163, 189, 0.16) !important;
    backdrop-filter: blur(12px) !important;

    /* Note: backdrop-filter has minimal browser support */
    border-radius: 54px !important;
}

.pagination li a {
    border: 1px solid lightgray;
    padding: 5px 10px;
    cursor: pointer;
    color: #007bff;
}

.pagination .active a {
    background-color: #007bff;
    color: white;
    border-color: #007bff;
}

.action_menu {
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin: 10px;
    margin-top: 20px;
}

.listHeader {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.accordion_body {
    background-color: #F6F8FC !important;
}

.listHeaderItem {
    flex: 1 1 px;
    flex-direction: column;
    width: 100%;
}

.listHeaderItemFlag {
    display: flex;
    width: 100%;
    padding-right: 20px;
    justify-content: flex-end;
}

.listHeaderItemID {
    display: flex;
    width: 100%;
    padding-right: 20px;
    justify-content: flex-start;
}

.descriptionContainer {
    display: flex;
}

.descriptionColumn {
    flex: 1 1
}

.defaultContainer {
    display: flex;
    flex: 1 1;
    flex-direction: row;
}

.defaultInput {
    margin-bottom: 30px;
    width: 100%;
    height: 40px;
    z-index: 10;
    width: 100%;
    /*taken over from figma*/
    background: linear-gradient(92.46deg, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.7) 100%);
    border: 2px solid rgba(0, 0, 0, 0.3);
    box-sizing: border-box;
    box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(12px);
    border-radius: 12px;
    margin-bottom: 20px;
}

.headerImageContainer {
    position: relative;
    text-align: center;
    color: white;
    margin-left: 20px;
}

.pagination {
    flex-wrap: wrap;
}

.centerdFlagText {
    position: absolute;
    top: 28%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.centerdHeartText {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: black;
}

.filterButton {
    width: 25%;
    height: 40px;
    margin-left: 10px;

    /*taken over from figma*/
    background: #FFFFFF;
    border-radius: 100px !important;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    border-width: 3px;
    border-color: darkgray;
    color: #7F8FA6;
    margin-right: 10px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;

}

.defaultButton {
    width: 25%;
    height: 40px;
    margin-left: 10px;

    /*taken over from figma*/
    background: #FFFFFF;
    border-radius: 100px !important;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    border-width: 3px;
    border-color: darkgray;
    color: #7F8FA6;
    margin-right: 10px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;

}