.location_container_row {
    display: flex;
    flex-direction: row;
}

.location_container_column {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-left: 10px;
    flex-grow: 0;
    width: 50%;
}

.image_container 
{
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    padding: 10px;
    margin-left: 10px;
}

.one_column_container_column {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    margin-top: 10px;
}

.location_header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.one_column_container_row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-left: 10px;
    margin-top: 10px;
}


.default_category_container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 10px;
    margin-left: 10px;
}


.default_container_buttonbar {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.Search_Container_Buttonbar {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.Search_Container_Buttonbar Button {
    width: 25%;
    flex-grow: 1;
    /*taken over from figma*/
    margin-top: 10px;
    background: #f6f8fc ;
    border-radius: 100px !important;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    border-width: 0;
    color: #7F8FA6;
    margin-right: 10px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}

.Load_Button {
    margin-left: 20px;
    background: linear-gradient(90deg, rgba(74, 84, 255, 0.9) 0%, rgba(0, 102, 255, 0.6) 100%) !important;
    border: 1px solid rgba(252, 252, 252, 0.5) !important;
    box-sizing: border-box !important;

    /* Primary */
    box-shadow: 0px 16px 24px rgba(160, 163, 189, 0.16) !important;
    backdrop-filter: blur(12px) !important;

    /* Note: backdrop-filter has minimal browser support */
    border-radius: 54px !important;


}
.default_container_buttonbar Button {
    width: 25%;
    flex-grow: 1;
    /*taken over from figma*/
    margin-top: 10px;
    background: #FFFFFF;
    border-radius: 100px !important;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    border-width: 0;
    color: #7F8FA6;
    margin-right: 10px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}


.targetgroup_container_buttonbar {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.reason_container_buttonbar {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.reason_container_buttonbar Button {
    width: 15%;
    flex-grow: 1;
    /*taken over from figma*/
    margin-top: 10px;
    background: #FFFFFF;
    border-radius: 100px !important;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    border-width: 0;
    color: #7F8FA6;
    margin-right: 10px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}

.flag_remove_Button {
   background-color: #DC3545; 
   border-color: transparent;
   margin-top: 25px;
   align-self: flex-end;
}

.targetgroup_container_buttonbar Button {
    width: 33%;
    flex-grow: 1;
    /*taken over from figma*/
    margin-top: 10px;
    background: #FFFFFF;
    border-radius: 100px !important;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    border-width: 0;
    color: #7F8FA6;
    margin-right: 10px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}


.default_container {
    margin-top: 20px;
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    z-index: 1;
}

.autcomplete_container {
    z-index: 10;
    margin-top: 20px;
    margin-left: 10px;
    display: flex;
    flex-direction: column;
}

.age_container {
    padding-top: 30px;
}

body {
    background: linear-gradient(92.46deg, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.7) 100%);
}

.autcomplete_container input {
    z-index: 10;
    width: 100%;
    /*taken over from figma*/
    background: linear-gradient(92.46deg, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.7) 100%);
    border: 1px solid rgba(255, 255, 255, 0.5);
    box-sizing: border-box;
    box-shadow: 0px 8px 32px rgba(110, 113, 145, 0.15);
    backdrop-filter: blur(12px);
    /* Note: backdrop-filter has minimal browser support */
    border-radius: 12px;
    margin-bottom: 20px;

}

.default_container Input {
    width: 100%;
    /*taken over from figma*/
    background: linear-gradient(92.46deg, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.7) 100%);
    border: 1px solid rgba(255, 255, 255, 0.5);
    box-sizing: border-box;
    box-shadow: 0px 8px 32px rgba(110, 113, 145, 0.15);
    backdrop-filter: blur(12px);
    /* Note: backdrop-filter has minimal browser support */
    border-radius: 12px;
    margin-bottom: 20px;

}

.default_container textarea {
    width: 100%;
    /*taken over from figma*/
    background: linear-gradient(92.46deg, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.7) 100%);
    border: 1px solid rgba(255, 255, 255, 0.5);
    box-sizing: border-box;
    padding: .375rem .75rem;
    box-shadow: 0px 8px 32px rgba(110, 113, 145, 0.15);
    backdrop-filter: blur(12px);

    /* Note: backdrop-filter has minimal browser support */
    border-radius: 12px;
    margin-bottom: 20px;
}



.autoSuggest {
    width: 100%;
}

.dropDowmSuggestions {
    position: absolute;
    background-color: #7F8FA6;
    width: 100%;
    height: 100%;
    z-index: 10000;
}

.upload_image_container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-left: 10%;
}

.upload_image_Container {
    display: inline-flex;
    flex-direction: row;
    align-items: flex-end;
    align-content: flex-end;
    align-self: flex-end;
    flex: 2
}

.upload_image_button {
    min-width: 0;
    flex-grow: 1;
    overflow: hidden;
    /*taken over from figma*/
    margin-top: 10px;
    background: #FFFFFF;
    border-radius: 100px !important;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    border-width: 0;
    color: #7F8FA6;
    margin-right: 10px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    width: 50px;
    height: 50px;


}
.container_costs {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
}

.container_costs Button {
    width: 100%;
    flex-grow: 1;
    /*taken over from figma*/
    margin-top: 10px;
    background: #FFFFFF;
    border-radius: 100px !important;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    border-width: 0;
    color: #7F8FA6;
    margin-right: 10px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}

.submit_button_container {
    display: flex;
    justify-content: flex-end;

}

.input-range--disabled .input-range__track {
    background: #eeeeee !important;
}

.input-range__track--active,
.input-range__slider {
    background: #5596fd !important;
    border-color: #5596fd !important;
}

.delete_button_container {
    display: flex;
    justify-content: flex-start;
}

.delete_button_container Button {
    background: linear-gradient(90deg, rgba(74, 84, 255, 0.9) 0%, rgba(0, 102, 255, 0.6) 100%) !important;
    border: 1px solid rgba(252, 252, 252, 0.5) !important;
    box-sizing: border-box !important;

    /* Primary */
    box-shadow: 0px 16px 24px rgba(160, 163, 189, 0.16) !important;
    backdrop-filter: blur(12px) !important;

    /* Note: backdrop-filter has minimal browser support */
    border-radius: 54px !important;
}

.submit_button_container Button {
    background: linear-gradient(90deg, rgba(74, 84, 255, 0.9) 0%, rgba(0, 102, 255, 0.6) 100%) !important;
    border: 1px solid rgba(252, 252, 252, 0.5) !important;
    box-sizing: border-box !important;

    /* Primary */
    box-shadow: 0px 16px 24px rgba(160, 163, 189, 0.16) !important;
    backdrop-filter: blur(12px) !important;

    /* Note: backdrop-filter has minimal browser support */
    border-radius: 54px !important;
}

/* Missing  Fields*/

.input_field_missing {
    border: 2px solid #ff9e2f60 !important;
    border-radius: 7px !important;
}

.input_field_missing_focus {
    outline: none !important;
    border: 2px solid #ff9e2f60 !important;
}

.places_missing  {
    outline: none !important;
    border: 2px solid #ff9e2f60 !important;
}

.default_container_buttonbar_missing{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    
}

.default_container_buttonbar_missing Button {
    width: 25%;
    flex-grow: 1;
    /*taken over from figma*/
    margin-top: 10px;
    background: #FFFFFF;
    border-radius: 100px !important;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    border-width: 0;
    color: #7F8FA6;
    margin-right: 10px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    outline: none !important;
    border: 3px solid #ff9e2f60 !important;
}

.targetgroup_container_buttonbar_missing {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.targetgroup_container_buttonbar_missing Button {
    width: 33%;
    flex-grow: 1;
    /*taken over from figma*/
    margin-top: 10px;
    background: #FFFFFF;
    border-radius: 100px !important;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    border-width: 0;
    color: #7F8FA6;
    margin-right: 10px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    outline: none !important;
    border: 2px solid #ff9e2f60 !important;

}   