@import url('https://fonts.googleapis.com/css?family=Poppins');
.next-steps .fa-link {
  margin-right: 5px;
}

/* Fix for use only flexbox in content area */
.next-steps .row {
  margin-bottom: 0;
}

.next-steps .col-md-5 {
  margin-bottom: 3rem;
}

@media (max-width: 768px) {
  .next-steps .col-md-5 {
    margin-bottom: 0;
  }
}

.center {
  margin: auto;
  width: 80%;
  padding: 10px;
}

.spinner {
  position: fixed;
  display: flex;
  justify-content: center;
  background-color: white;
  z-index: 1000;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.spinner_hidden {
  position: absolute;
  display: none;
  justify-content: top;
  background-color: white;
  z-index: 1000;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.result-block-container .result-block {
  opacity: 1;
}

.logo-custom {
  background-image: url('./assets/logo.png');
  background-size: contain;
  background-repeat: no-repeat;
  width: 100px;
  height: 100px;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.bg-test {
  background-color: 'red';
}

.tab_panel_container {
  background-color: #F6F8FC !important;
}