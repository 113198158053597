/* Modal Overlay Styles */
.adv-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

/* Modal Window Styles */
.adv-modal {
    display: block;
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    width: 500px;
    height: 300px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 10000;
    position: relative;
}

.defaultModalInput {
    width: 100%;
    height: 40px;
    border: 2px solid rgba(0, 0, 0, 0.3);
    box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.15);
    box-sizing: border-box;
    border-radius: 12px !important;
}
/* Input Group Styles */
.input-group {
    margin-bottom: 15px;
}

/* Close Button Styles */
.close-button {
    margin-left: 10px;
}