/* ImageModal.css */
.image_modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .image_modal-content {
    position: relative;
    width: auto;
    width: auto;
  }
  
  .image_modal-content img {
    width: auto;
    height: auto;
  }
  
  .image_close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    color: black;
    font-size: 24px;
    cursor: pointer;
  }