.container {
  display: grid;
  place-items: end end;
}

.image {
  grid-area: 1/1;
  width: 112px;
  height: 112px;
  margin-bottom: 10px;
}
.circle{
  grid-area: 1/1;
  border-radius: 50%;
  margin: 13px;
  align-items: center;
  justify-content: center;
  background-color: white;
  width: 25px;
  height: 25px;
  position: relative;
}

.trashbin {
  grid-area: 1/1;
  position: relative;
  padding-left: 5px;
}